import React from "react";

const mobile_breakpoint = 600;
const tablet_breakpoint = 1000;
const desktop_breakpoint = 1400;
const large_breakpoint = 1900;

const HeroImage = ( { sectionClass, altText, mobileImage, mobileSrcSet, tabletSrcSet, desktopSrcSet, largeSrcSet, hugeSrcSet } ) => {
  let imageAttributes = "";
  if( altText ) {
    imageAttributes+= "alt='"+altText+"'";
    imageAttributes+= "aria-label='"+altText+"'";
  } else {
    imageAttributes+= "role='presentation'";
  }
  return (
    <picture className={sectionClass}>
      <source media={"(min-width: "+large_breakpoint+"px)"} data-srcset={hugeSrcSet} />
      <source media={"(min-width: "+desktop_breakpoint+"px)"} data-srcset={largeSrcSet} />
      <source media={"(min-width: "+tablet_breakpoint+"px)"} data-srcset={desktopSrcSet} />
      <source media={"(min-width: "+mobile_breakpoint+"px)"} data-srcset={tabletSrcSet} />
      <source media={"(max-width: "+mobile_breakpoint+"px)"} data-srcset={mobileSrcSet} />
      <img className="lazyload" data-src={mobileImage} />
    </picture>
  )
};

export default HeroImage;
