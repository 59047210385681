import "./Footer.scss";
import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="footer-bottom">
      <div className="footer-responsibly">
        <p className="footer-responsibly-elem">ENJOY RESPONSIBLY </p>
        <p className="footer-responsibly-elem">SOYEZ RESPONSABLE </p>
      </div>
      <div className="footer-trademark">
        <p className="footer-trademark__text">We support responsible decision-making. Visit <a data-id="bacardi_respons_drink" href="http://www.responsibledrinking.org" target="_blank" rel="nofollow noopener">www.responsibledrinking.org</a> and <a data-id="bacardi_respons" href="https://www.responsibility.org" target="_blank" rel="nofollow noopener">responsibility.org</a></p>
        <p className="footer-trademark__text">© 2020 ST~GERMAIN, its trade dress and vie parisienne en bouteille are trademarks. All other marks are trademarks of their respective owners.</p>
        <p className="footer-trademark__text">Imported by Maison 6ème Arr., Coral Gables, Florida. 20% Alc By Vol. Liqueur</p>
      </div>
      <ul id="menu-footer" className="footer-nav">
        <li className="footer-nav-item"><a data-id="brand-39" className="footer-nav-item__link" href="https://www.stgermain.fr/us/en/terms-and-conditions/" rel="noopener">Terms &amp; Conditions</a></li>
        <li className="footer-nav-item"><a data-id="brand-432" className="footer-nav-item__link" href="https://contact.stgermain.fr/hc/en-us/requests/new?ticket_form_id=151485" rel="noopener">Contact Us</a></li>
        <li className="footer-nav-item"><a data-id="brand-36" className="footer-nav-item__link" href="https://www.stgermain.fr/us/en/privacy-policy/" rel="noopener">Privacy Policy</a></li>
        <li className="footer-nav-item"><a data-id="brand-35" className="footer-nav-item__link" href="https://www.stgermain.fr/us/en/cookie-policy/" rel="noopener">Cookie Policy</a></li>
        <li className="footer-nav-item"><a data-id="brand-566" className="footer-nav-item__link" target="_blank" href="http://media.bacardilimited.com/en/" rel="noopener">Media</a></li>
        <li className="footer-nav-item"><a data-id="brand-567" className="footer-nav-item__link" target="_blank" href="https://careers.bacardilimited.com/" rel="noopener">Careers</a></li>
      </ul>
    </div>
  </footer>
);

export default Footer;
