import React from "react";
import "lazysizes";
import { Helmet } from "react-helmet";
import "../styles/screen.scss";
import HeaderSection from "../components/HeaderSection/";
import HeroSection from "../components/HeroSection/";
import AboutSection from "../components/AboutSection/";
import Sweepstakes from "../components/SweepSection/";
import SocialSection from "../components/SocialSection/";
import CocktailSection from "../components/CocktailSection/";
import Footer from "../components/Footer/";
import Cookies from 'react-cookie';
import Cookie from 'js-cookie';

if( !Cookie.get('mtmbviewed') ) {
  const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
  const site_domain = (activeEnv == "production" || activeEnv == "primo") ? "stgermain.fr" : "bacardistaging.com";
  Cookie.set('mtmbviewed', 'true', { expires: 1, path: '', domain: site_domain })
}

export default function Home() {

  return <main className="site-wrapper">
    <Helmet>
      <title>St~Germain Make The Moment Bloom</title>
      <meta name="description" content=""/>
      <meta property="og:title" content="St~Germain Make The Moment Bloom" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="" />
      <meta property="og:image" content="" />
      <meta property="og:locale" content="en_US"/>
      <meta property="og:url" content="" />
      <link rel="canonical" href=""/>
    </Helmet>
    <HeaderSection />
    <HeroSection />
    <AboutSection />
    <Sweepstakes />
    <SocialSection />
    <CocktailSection />
    <Footer />
  </main>
}
