import "./SocialSection.scss";
import SocialDesktopImage from "./assets/STG20_Desktop_2880_Module4_ImageGrid.jpg";
import SocialMobileImageTop from "./assets/STG20_Mobile_960_Module4_TopImage.jpg";
import SocialMobileImageBottom from "./assets/STG20_Mobile_960_Module4_BottomImage.jpg";
import React from "react";

const SocialSection = () => (
  <section className="section mtmb-social-section">
    <div className="mtmb-social-section__inner">
      <aside className="mtmb-social-section__aside hide-mobile">
        <div><h4 className="mtmb-header-4" data-sal="fade" data-sal-duration="800" data-sal-delay="200" data-sal-easing="ease">#makethemomentbloom</h4></div>
      </aside>
      <div className="mtmb-social-section__mobile-img-top">
        <span className="mtmb-elderflower-5"></span>
        <picture>
            <source data-srcset={SocialMobileImageTop} media="(min-width: 568px)" />
            <img className="mtmb-social-section__img lazyload" data-src={SocialMobileImageTop} alt="ST~GERMAIN cocktails collage" />
        </picture>
        <span className="mtmb-elderflower-4"></span>
      </div>
      <div className="mtmb-social-section__left">
        <h2 className="mtmb-social-section__heading mtmb-header-1" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">SHARE YOUR ST~GERMAIN COCKTAIL MOMENT</h2>
        <span className="mtmb-fancy-spacer" data-sal="fade" data-sal-duration="800" data-sal-easing="ease"></span>
        <div className="mtmb-social-section__body">
          <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">How do you #makethemomentbloom? Show us how you add a little je ne sais quoi to your cocktails.</p>
        </div>
        <a href="https://www.instagram.com/stgermaindrinks/" className="mtmb-button" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">explore more</a>
        <span className="mtmb-elderflower-2"></span>
      </div>
      <div className="mtmb-social-section__mobile-img-bottom">
        <picture>
            <source data-srcset={SocialMobileImageBottom} media="(min-width: 568px)" />
            <img className="mtmb-social-section__img lazyload" data-src={SocialMobileImageBottom} alt="ST~GERMAIN cocktails collage" />
        </picture>
      </div>
      <div className="mtmb-social-section__right">
        <picture>
            <source data-srcset={SocialDesktopImage} media="(min-width: 568px)" />
            <img className="mtmb-social-section__img lazyload" data-src={SocialDesktopImage} alt="ST~GERMAIN cocktails collage" />
        </picture>
      </div>
    </div>
    <span className="mtmb-elderflower-3"></span>
  </section>
)

export default SocialSection;
