import "./HeroSection.scss";
import HeroImage from "./HeroImage.js";
import mobile_backdrop from "./assets/mobile/backdrop.jpg";
import mobile_backdrop_half from "./assets/mobile/backdrop@1.5x.png";
import mobile_backdrop_double from "./assets/mobile/backdrop@2x.png";
import tablet_backdrop from "./assets/tablet/backdrop.jpg";
import tablet_backdrop_half from "./assets/tablet/backdrop@1.5x.jpg";
import tablet_backdrop_double from "./assets/tablet/backdrop@2x.png";
import desktop_backdrop from "./assets/desktop/backdrop.jpg";
import large_backdrop from "./assets/large/backdrop.jpg";
import huge_backdrop from "./assets/huge/backdrop.jpg";
import mobile_text from "./assets/mobile/text.png";
import mobile_text_half from "./assets/mobile/text@1.5x.png";
import mobile_text_double from "./assets/mobile/text@2x.png";
import tablet_text from "./assets/tablet/text.png";
import tablet_text_half from "./assets/tablet/text@1.5x.png";
import tablet_text_double from "./assets/tablet/text@2x.png";
import desktop_text from "./assets/desktop/text.png";
import large_text from "./assets/large/text.png";
import huge_text from "./assets/huge/text.png";
import mobile_floral from "./assets/mobile/floral.png";
import mobile_floral_half from "./assets/mobile/floral@1.5x.png";
import mobile_floral_double from "./assets/mobile/floral@2x.png";
import tablet_floral from "./assets/tablet/floral.png";
import tablet_floral_half from "./assets/tablet/floral.png";
import tablet_floral_double from "./assets/tablet/floral.png";
import desktop_floral from "./assets/desktop/floral.png";
import large_floral from "./assets/large/floral.png";
import huge_floral from "./assets/huge/floral.png";
import mobile_neon from "./assets/mobile/neon.png";
import mobile_neon_half from "./assets/mobile/neon@1.5x.png";
import mobile_neon_double from "./assets/mobile/neon@2x.png";
import tablet_neon from "./assets/tablet/neon.png";
import tablet_neon_half from "./assets/tablet/neon@1.5x.png";
import tablet_neon_double from "./assets/tablet/neon@2x.png";
import desktop_neon from "./assets/desktop/neon.png";
import large_neon from "./assets/large/neon.png";
import huge_neon from "./assets/huge/neon.png";
import mobile_leaves from "./assets/mobile/leaves.png";
import mobile_leaves_half from "./assets/mobile/leaves@1.5x.png";
import mobile_leaves_double from "./assets/mobile/leaves@2x.png";
import tablet_leaves from "./assets/tablet/leaves.png";
import tablet_leaves_half from "./assets/tablet/leaves@1.5x.png";
import tablet_leaves_double from "./assets/tablet/leaves@2x.png";
import desktop_leaves from "./assets/desktop/leaves.png";
import large_leaves from "./assets/large/leaves.png";
import huge_leaves from "./assets/huge/leaves.png";
import React from "react";

const HeroSection = () => (
  <section className="mtmb-hero">
    <h1 className="mtmb-header-1 visuallyhidden">Spritz Up Your Bar Cart with a dash of fresh elderflowers</h1>
    <HeroImage
      sectionClass="mtmb-hero-backdrop"
      altText={false}
      mobileImage={mobile_backdrop}
      mobileSrcSet={mobile_backdrop+", "+mobile_backdrop_half+" 1.5x, "+mobile_backdrop_double+" 2x"}
      tabletSrcSet={tablet_backdrop+", "+tablet_backdrop_half+" 1.5x, "+tablet_backdrop_double+" 2x"}
      desktopSrcSet={desktop_backdrop+", "+large_backdrop+" 1.5x, "+huge_backdrop+" 2x"}
      largeSrcSet={large_backdrop}
      hugeSrcSet={huge_backdrop}
    />
    <HeroImage
      sectionClass="mtmb-hero-text"
      altText="Spritz Up Your Bar Cart with a dash of fresh elderflowers"
      mobileImage={mobile_text}
      mobileSrcSet={mobile_text+", "+mobile_text_half+" 1.5x, "+mobile_text_double+" 2x"}
      tabletSrcSet={tablet_text+", "+tablet_text_half+" 1.5x, "+tablet_text_double+" 2x"}
      desktopSrcSet={desktop_text+", "+large_text+" 1.5x, "+huge_text+" 2x"}
      largeSrcSet={large_text}
      hugeSrcSet={huge_text}
    />
    <HeroImage
      sectionClass="mtmb-hero-floral"
      altText={false}
      mobileImage={mobile_floral}
      mobileSrcSet={mobile_floral+", "+mobile_floral_half+" 1.5x, "+mobile_floral_double+" 2x"}
      tabletSrcSet={tablet_floral+", "+tablet_floral_half+" 1.5x, "+tablet_floral_double+" 2x"}
      desktopSrcSet={desktop_floral+", "+large_floral+" 1.5x, "+huge_floral+" 2x"}
      largeSrcSet={large_floral}
      hugeSrcSet={huge_floral}
    />
    <HeroImage
      sectionClass="mtmb-hero-neon"
      altText={false}
      mobileImage={mobile_neon}
      mobileSrcSet={mobile_neon+", "+mobile_neon_half+" 1.5x, "+mobile_neon_double+" 2x"}
      tabletSrcSet={tablet_neon+", "+tablet_neon_half+" 1.5x, "+tablet_neon_double+" 2x"}
      desktopSrcSet={desktop_neon+", "+large_neon+" 1.5x, "+huge_neon+" 2x"}
      largeSrcSet={large_neon}
      hugeSrcSet={huge_neon}
    />
    <HeroImage
      sectionClass="mtmb-hero-leaves"
      altText="Spritz up your bar cart with a dash of elderflowers"
      mobileImage={mobile_leaves}
      mobileSrcSet={mobile_leaves+", "+mobile_leaves_half+" 1.5x, "+mobile_leaves_double+" 2x"}
      tabletSrcSet={tablet_floral+", "+tablet_leaves_half+" 1.5x, "+tablet_leaves_double+" 2x"}
      desktopSrcSet={desktop_leaves+", "+large_leaves+" 1.5x, "+huge_leaves+" 2x"}
      largeSrcSet={large_leaves}
      hugeSrcSet={huge_leaves}
    />
  </section>
);

export default HeroSection;
