import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DrinkRoyal_desktop from "./assets/STG20_Desktop_2880_Module5_Drink1_Royal.png";
import DrinkRoyal_mobile from "./assets/STG20_Mobile_480_Module5_Drink1_Royal.png";
import DrinkElderfashioned_desktop from "./assets/STG20_Desktop_2880_Module5_Drink2_Elderflower.png";
import DrinkElderfashioned_mobile from "./assets/STG20_Mobile_480_Module5_Drink2_Elderfashioned.png";
import DrinkGinTonic_desktop from "./assets/STG20_Desktop_2880_Module5_Drink3_GinTonic.png";
import DrinkGinTonic_mobile from "./assets/STG20_Mobile_480_Module5_Drink3_Tonic.png";
import DrinkRita_desktop from "./assets/STG20_Desktop_2880_Module5_Drink4_Rita.png";
import DrinkRita_mobile from "./assets/STG20_Mobile_480_Module5_Drink4_Rita.png";
import DrinkSpritz_desktop from "./assets/STG20_Desktop_2880_Module5_Drink5_Spritz.png";
import DrinkSpritz_mobile from "./assets/STG20_Mobile_480_Module5_Drink5_Spritz.png";
import React from "react";

export default function Carousel() {
  var settings = {
    arrows: true,
    autoplay: false,
    dots: false,
    draggable: true,
    infinite: true,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          arrows: false,
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
      breakpoint: 640,
        settings: {
          arrows: false,
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 4,
    speed: 800,
  };
  return (
    <Slider {...settings}>
      <div>
        <a href="https://www.stgermain.fr/cocktails/the-st-germain-spritz/22536">
          <div className="mtmb-carousel-image">
            <picture>
                <source data-srcset={DrinkSpritz_desktop} media="(min-width: 568px)" />
                <img className="lazyload" data-src={DrinkSpritz_mobile} alt="ST~GERMAIN Spritz" />
            </picture>
          </div>
          <div className="mtmb-carousel-heading">
            <h3 className="mtmb-header-3">The ST~GERMAIN {"\n"} Spritz</h3>
          </div>
        </a>
      </div>
      <div>
        <a href="https://www.stgermain.fr/cocktails/the-st-germain-royale/22538">
          <div className="mtmb-carousel-image">
            <picture>
                <source data-srcset={DrinkRoyal_desktop} media="(min-width: 568px)" />
                <img className="lazyload" data-src={DrinkRoyal_mobile} alt="ST~GERMAIN ROYALE" />
            </picture>
          </div>
          <div className="mtmb-carousel-heading">
            <h3 className="mtmb-header-3">THE ST~GERMAIN ROYALE</h3>
          </div>
        </a>
      </div>
      <div>
        <a href="https://www.stgermain.fr/cocktails/traditional-elderfashioned/22551">
          <div className="mtmb-carousel-image">
            <picture>
                <source data-srcset={DrinkElderfashioned_desktop} media="(min-width: 568px)" />
                <img className="lazyload" data-src={DrinkElderfashioned_mobile} alt="ST~GERMAIN Elderfashioned" />
            </picture>
          </div>
          <div className="mtmb-carousel-heading">
            <h3 className="mtmb-header-3">THE TRADITIONAL ELDERFASHIONED</h3>
          </div>
        </a>
      </div>
      <div>
        <a href="https://www.stgermain.fr/cocktails/the-st-germain-gin-and-tonic/22537">
          <div className="mtmb-carousel-image">
            <picture>
                <source data-srcset={DrinkGinTonic_desktop} media="(min-width: 568px)" />
                <img className="lazyload" data-src={DrinkGinTonic_mobile} alt="ST~GERMAIN Gin and Tonic" />
            </picture>
          </div>
          <div className="mtmb-carousel-heading">
            <h3 className="mtmb-header-3">THE ST~GERMAIN <span className="gin-tonic-text">GIN & TONIC</span></h3>
          </div>
        </a>
      </div>
      <div>
        <a href="https://www.stgermain.fr/cocktails/st-rita/22550">
          <div className="mtmb-carousel-image">
            <picture>
                <source data-srcset={DrinkRita_desktop} media="(min-width: 568px)" />
                <img className="lazyload" data-src={DrinkRita_mobile} alt="St-Rita" />
            </picture>
          </div>
          <div className="mtmb-carousel-heading">
            <h3 className="mtmb-header-3">THE ST~RITA</h3>
          </div>
        </a>
      </div>
    </Slider>
  );
}
