import "./CocktailSection.scss";
import Carousel from "./Carousel.js";
import React from "react";

const CocktailSection = () => (
  <section className="mtmb-cocktail-section">
    <span className="mtmb-elderflower-1"></span>
    <div className="mtmb-cocktail-section__inner">
      <div className="mtmb-cocktail-section__header">
        <h2 className="mtmb-header-1" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">INFINITE WAYS TO ELEVATE YOUR COCKTAILS</h2>
        <div className="hide-mobile">
          <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">As the most versatile tool on your bar cart, it's as simple as adding a dash of ST~GERMAIN to make your cocktails bloom.</p>
          <a href="https://www.stgermain.fr/cocktails" className="mtmb-button" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">explore recipes</a>
        </div>
      </div>
      <Carousel />
      <div className="mtmb-cocktail-section__header hide-desktop">
        <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">As the most versatile tool on your bar cart, it's as simple as adding a dash of ST~GERMAIN to make your cocktails bloom.</p>
        <a href="https://www.stgermain.fr/cocktails" className="mtmb-button" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">explore recipes</a>
      </div>
    </div>
    <span className="mtmb-elderflower-2"></span>
  </section>
)

export default CocktailSection;
