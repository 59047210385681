import "./SweepSection.scss";
import mobile_carafe_img from "./assets/carafe-small.jpg";
import desktop_carafe_img from "./assets/carafe-large.jpg";
import mobile_neon_image from "./assets/neon-small.png";
import desktop_neon_image from "./assets/neon-large.png";
import React from "react";

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

let rules_content = "Sweepstakes open to residents of the United States except Colorado. Winners will be chosen at random, and be notified by email. Sweepstakes ends at 11:59 PM ET on February 28, 2021.";
if (activeEnv === "primo" || activeEnv === "stagingprimo") {
  rules_content = "Primo Sweepstakes open only to current employees of Bacardi U.S.A., Inc. (“BUSA”).  Winners will be chosen at random and notified by email, using the contact information on the entry form. Primo Sweepstakes ends on 11:59PM (ET) on February 28, 2021.";
}

const SweepSection = () => (
  <section className="mtmb-sweepstakes">
    <div className="mtmb-elderflower mtmb-elderflower-1"></div>
    <div className="mtmb-sweepstakes-border"></div>
    <div className="mtmb-sweepstakes-content">
      <div className="mtmb-sweepstakes-about">
        <h2 className="mtmb-header-1" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">SPRITZ UP YOUR <span className="bar-cart-text">BAR CART</span></h2>
        <picture className="mtmb-sweepstakes-img">
          <source media={"(max-width: 800px)"} data-srcset={mobile_carafe_img} />
          <img className="lazyload" data-src={desktop_carafe_img} alt="Girl pouring ST~GERMAIN into carafe." />
        </picture>
        <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">Enter our ST~GERMAIN® Make the Moment Bloom sweepstakes and you may bring home our ST~GERMAIN Spritz essentials and give a bit of sparkle to your home cocktails.</p>
        <h3 className="mtmb-header-3" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">Winners will receive</h3>
        <ul className="mtmb-body mtmb-sweepstakes-list" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">
          <li>1 large ST~GERMAIN carafe</li>
          <li>1 set of two gold-rimmed ST~GERMAIN glasses</li>
          <li>1 set of two ST~GERMAIN metal stirrers</li>
          <li>1 set of four ST~GERMAIN steel spoon straws</li>
          <li>1 “How to Spritz French Fluently” recipe book</li>
          <li>1 Phillipe Ashley Chocolate Box</li>
        </ul>
      </div>
      <div className="mtmb-sweepstakes-enter">
        <picture className="mtmb-sweepstakes-neon-img">
          <source media={"(max-width: 800px)"} data-srcset={mobile_neon_image} />
          <img className="lazyload" data-src={desktop_neon_image} role="presentation" />
        </picture>
        <div className="mtmb-sweepstakes-enter-content">
          <h3 className="mtmb-header-sweeps" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">YOUR CHANCE TO WIN SOME SPRITZ ESSENTIALS</h3>
          <div className="mtmb-fancy-spacer" data-sal="fade" data-sal-duration="800" data-sal-easing="ease"></div>
          <p className="mtmb-body-sweeps" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">{rules_content}</p>
          <button className="mtmb-button" id="js-spotlight-launch" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">Enter To Win</button>
          <p className="mtmb-small-print" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">* Subject to availability. I agree to <a href="/terms/">the Official Rules, Terms and Conditions</a>, and <a href="https://www.stgermain.fr/us/en/privacy-policy/">Privacy Policy</a>. I want to receive information about ST~GERMAIN and other spirit brands of the Bacardi Limited group via email and digital channels including social media.</p>
        </div>
      </div>
      <div className="mtmb-sweepstakes-extra">
        <h3 className="mtmb-header-3" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">Can't Wait?</h3>
        <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">Explore our ST~GERMAIN boutique of glassware and bar cart accessories to give your home bar a little ooh la la. <a href="https://shop.stgermain.fr/?_ga=2.64712043.570789441.1606322646-972816675.1603997898">Shop Now</a></p>
      </div>
    </div>
    <div className="mtmb-elderflower mtmb-elderflower-2"></div>
  </section>
);

export default SweepSection;
